import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'
import Image from '../components/Image/Image'
import Companies from '../components/Companies/Companies'

import Try from '../sections/Try/Try'

const AboutPage = () => {
  return (
    <Layout pageName="about">
      <Seo title="About" />
      <Container>
        <section className="hero">
          <h1 className="heading heading--md">
            Make work life simpler, more pleaseant and more productive.
          </h1>
          <p className="hero__text">
            Ally is the team visibility hub that brings the right people,
            information, and tools together to get work done. From Fortune 100
            companies to corner markets, thousands of people around the world
            use Ally to understand their teams, unify their systems, and drive
            their business forward.
          </p>
          <p className="hero__text">
            Ally is the the product of <a href="https://www.moveworkforward.com" target="_blank">Move Work Forward</a>, who 
            provides <a href="https://www.moveworkforward.com/product/microsoft-teams-jira-connector" target="_blank">Microsoft Teams Jira integration</a>, 
             <a href="https://www.moveworkforward.com/product/microsoft-teams-confluence-connector" target="_blank">Microsoft Teams Confluence integration</a>, 
             <a href="https://www.moveworkforward.com/product/microsoft-teams-bitbucket-connector" target="_blank">Microsoft Teams Bitbucket integration</a>, 
             <a href="https://www.moveworkforward.com/product/microsoft-teams-bamboo-connector" target="_blank">Microsoft Teams Bamboo integration</a>.
          </p>
          <Image
            filename="about-hero"
            alt="Make work life simpler"
            className="hero__img"
          />
          <p className="hero__companies-label visible-only-mobile">
            Trusted by
          </p>
          <Companies className="hero__companies" />
        </section>

        <section className="build">
          <h2 className="heading heading--md">
            Building the transparency ecosystem for work
          </h2>
          <p>
            Understand and improve your daily work life with team visibility
            suite to move work forward, daily.
          </p>
        </section>

        <section className="who">
          <h2 className="heading heading--md">Who we are and how to join us</h2>
          <p>
            We live by our mission, and improving people’s working life starts
            with our own company. We’re building a platform and products we
            believe in — as well as a strong, diverse team of curious, creative
            people who want to do the best work of their lives and support each
            other in the process.
          </p>
          <p>
            <a href="/" className="link link--blue">
              Our Leadership
            </a>
            includes some of the most inventive and experienced leaders in the
            technology industry.
          </p>
          <p>
            <a href="/" className="link link--blue">
              Careers
            </a>
            at Ally offer all kinds of opportunities and a simpler, more
            pleasant, more productive working life.
          </p>
          <p>
            <a href="/" className="link link--blue">
              Ally for Good
            </a>
            is aiming to increase transparency and openness of this world.
          </p>
        </section>

        <Try />
      </Container>
    </Layout>
  )
}

export default AboutPage
